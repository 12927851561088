/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
    {
      url: "/admin/dashboard",
      name: "Dashboard",
      slug: "dashboard",
      icon: "HomeIcon",
      i18n: "Dashboard",
    },
    {
      url: "/admin/admin-management",
      name: "Admin Management",
      slug: "adminManagement",
      icon: "UserCheckIcon",
      i18n: "Admin Management",
    },
    {
      url: "/admin/tutors",
      name: "Tutor Management",
      slug: "tutorManagement",
      icon: "BookIcon",
      i18n: "Tutor Management",
    },
    {
      url: "/admin/users",
      name: "Parent/Student Management",
      slug: "userManagement",
      icon: "UserIcon",
      i18n: "Parent/Student Management",
    },
    {
      url: "/admin/subjects",
      name: "Subject Management",
      slug: "pages",
      icon: "FileIcon",
      i18n: "Subject Management",
    },
    {
      url: "/admin/languages",
      name: "Language Management",
      slug: "pages",
      icon: "FileIcon",
      i18n: "Language Management",
    },
    {
      url: "/admin/levels",
      name: "Level Management",
      slug: "pages",
      icon: "FileIcon",
      i18n: "Level Management",
    },
    {
      url: "/admin/pages",
      name: "Page Management",
      slug: "pages",
      icon: "FileIcon",
      i18n: "Page Management",
    },
    {
      url: "/admin/email-templates",
      name: "Email Templates",
      slug: "email-templates",
      icon: "MailIcon",
      i18n: "Email Templates"
    },
    {
      url: "/admin/reports",
      name: "Reported Issues",
      slug: "reports",
      icon: "AlertCircleIcon",
      i18n: "Reported Issues",
    },
    {
      url: "/admin/report-titles",
      name: "Report Title Management",
      slug: "reports-titles",
      icon: "AlertCircleIcon",
      i18n: "Report Title Management",
    },
    {
      url: "/admin/transactions",
      name: "Transaction List",
      slug: "transactions",
      icon: "CreditCardIcon",
      i18n: "Transaction List",
    },
    {
      url: "/admin/site-setting",
      name: "Settings",
      slug: "setting",
      icon: "SettingsIcon",
      i18n: "Settings",
    },
  
  ]
  
  